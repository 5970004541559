<template>
  <div class="yh_wrapper">
    <ul class="yh_wrapper_ul">
      <template v-for="(item, ind) in titleList">
        <li
          :key="item.id + 'index' + ind"
          :class="active == ind ? 'active' : 'yh_li'"
          @click="navClick(ind)"
        >
          {{ item }}
        </li>
      </template>
    </ul>
    <live v-if="isLive"></live>
    <begin-show v-else></begin-show>
  </div>
</template>

<script>
import live from "./live";
import beginShow from "./beginShow";
export default {
  name: "collection",
  components: {
    live,
    beginShow,
  },
  data() {
    return {
      active: 0,
      userId: "",
      isLive: false,
      isBeginShow: false,
      titleList: ["点播", "直播"],
    };
  },
  mounted() {
    this.$EventBus.$emit("flag", true);
    // this.userId = JSON.parse(sessionStorage.getItem('profile')).userId
    // this.isLive = true
  },
  methods: {
    navClick(ind) {
      this.active = ind;
      if (ind == 0) {
        this.isLive = false;
      } else {
        this.isLive = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$color: var(--colorBlue);
.yh_wrapper {
  .yh_wrapper_ul {
    display: flex;
    margin-bottom: 18px;
    .yh_li {
      margin: 0 30px;
      width: 32px;
      height: 34px;
      color: #666;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;
    }
    .active {
      margin: 0 30px;
      width: 32px;
      height: 34px;
      border-bottom: 3px solid $color;
      color: $color;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;
    }
  }
}
</style>
