<template>
  <div class="yh_wrapper">
    <div
      class="yl_list"
      v-if="datas.length > 0"
      v-for="(item, ind) in datas"
      :key="item.id + 'ind' + ind"
    >
      <img class="yh_list_img" :src="item.coverUrl" alt="" />
      <div class="yh_list_cen">
        <h2>{{ item.courseTitle | filterTitle }}</h2>
        <p>
          <span
            >直播开始时间：{{ item.liveInfo.liveStartTime }}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {{ getDay(item.liveInfo.liveStartTime) }}</span
          >
        </p>
        <!-- <span class="yh_status color_blue">{{item.liveInfo.liveState | getStatus(item.liveInfo.liveState)}}</span> -->
      </div>
      <div class="yh_list_r" @click="collection(item.courseId)">
        <img src="@/assets/img/studentImg/file-icon.png" alt="" />
        <span class="color_blue">取消收藏</span>
      </div>
      <div
        class="yh_btn bg_blue"
        v-if="item.state == 1"
        @click="joinLive(item, 'pass')"
      >
        <span>进入直播</span>
      </div>
      <div
        class="yh_nopass bg_blue"
        v-if="item.state == 0 || item.state == 9"
        @click="joinLive(item, 'nopass')"
      >
        <span>进入直播</span>
      </div>
    </div>
    <!-- <div class="yh_btn bg_blue" :class="currentTime > getTimer(item.liveInfo.liveStartTime) ? 'yh_btn' : 'active'"  @click="joinLive(item)">
        <span>进入直播</span>
      </div> -->
    <div class="nodata" v-if="datas.length <= 0">
      <img src="@/assets/img/backStageImg/noData-icon.png" alt="" />
      <p>暂无数据</p>
    </div>
    <pagination
      class="pagination jumpBtn"
      v-if="total > 0"
      :pageSize="formData.pageSize"
      :total="total"
      @pageChange="pageChange"
      @sizeChange="sizeChange"
    />
  </div>
</template>

<script>
import API from "@/apis/studentsEndAPI/personalCenterAPI";
import { EventBus } from "@/assets/js/eventBus";
import { getDay, getStatus } from "@/assets/js/format.js";
import Pagination from "@comps/backStageComponent/pagination";
import moment from "moment";
export default {
  name: "live",
  components: {
    Pagination,
  },
  data() {
    return {
      total: "",
      searchType: 1,
      formData: {
        page: 1,
        pageSize: 6,
        courseType: 1,
        userId: "",
        keyword: "",
      },
      datas: [],
      currentTime: "",
    };
  },
  mounted() {
    this.currentTime = new Date().getTime();
    if (sessionStorage.getItem("profile")) {
      this.formData.userId = JSON.parse(
        sessionStorage.getItem("profile")
      ).userId;
    }
    this.getLive();
  },
  methods: {
    getDay,
    getTimer(time) {
      let data = moment(time).valueOf();
      return data;
    },
    async getLive() {
      let { page, pageSize, courseType, userId, keyword } = this.formData;
      let data = await API.getLive(page, pageSize, courseType, userId, keyword);
      if (data && !data.status) {
        this.datas = data.dataList;
        this.total = data.rowCount;
      }
    },
    async collection(itemId) {
      let userId = this.formData.userId;
      let params = {
        itemType: "1",
        itemId,
        collectType: "1",
      };
      let data = await API.deleteCollect(userId, params);
      if (data && !data.status) {
        this.getLive();
      }
    },
    joinLive(val, type) {
      // let data = moment(val.liveInfo.liveStartTime).valueOf()
      // if(this.currentTime < data || val.liveInfo.liveStartTime == null  ) {
      //   this.$message('当前时间不能小于开播时间')
      //   return
      // }else {
      // this.$router.push({
      //   path: '/liveDetail',
      //   query: {id: val.courseId}
      // })
      // }

      if (type == "pass") {
        this.$router.push({
          path: "/liveDetail",
          query: { id: val.courseId },
        });
      } else {
        this.$message("该课程已下架！");
        return;
      }
    },
    pageChange(val) {
      this.formData.page = val;
      this.getLive();
    },
    sizeChange(val) {
      this.formData.pageSize = val;
      this.getLive();
    },
  },
  filters: {
    getStatus,
    filterTitle(val) {
      if (!val) return "";
      return val.length > 15 ? val.substr(0, 15) + "..." : val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common.scss";
@import "@/assets/css/studentsEndCSS/liveAndbegin";
.active {
  background: #ccc !important;
}
.yh_nopass {
  position: absolute;
  right: 18px;
  bottom: 18px;
  width: 104px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  background: #ccc !important;
  span {
    color: #fff;
    line-height: 36px;
    cursor: pointer;
  }
}
.nodata {
  margin: auto;
  padding-top: 8%;
  img {
    width: 20%;
    height: 20%;
    padding-bottom: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  p {
    text-align: center;
    padding-bottom: 30px;
    font-size: 16px;
  }
}
</style>
