<template>
  <div class="yh_wrapper">
    <div
      class="yl_list"
      v-if="datas.length > 0"
      v-for="(item, ind) in datas"
      :key="item.id + 'ind' + ind"
    >
      <img class="yh_list_img" :src="item.coverUrl" alt="" />
      <div class="yh_list_cen">
        <h2>{{ item.courseTitle }}</h2>
        <p>
          <!-- 直播开始时间：{{item.liveInfo.liveStartTime}} &nbsp;&nbsp;&nbsp;&nbsp; -->
          <span> {{ getDay(item.liveInfo.liveStartTime) }}</span>
        </p>
      </div>
      <div class="yh_list_r" @click="collection(item.courseId)">
        <img src="@/assets/img/studentImg/file-icon.png" alt="" />
        <span>取消收藏</span>
      </div>
      <div class="yh_btn bg_blue" @click="joinLive(item)">
        <span>观看课程</span>
      </div>
    </div>
    <div class="nodata" v-if="datas.length <= 0">
      <img src="@/assets/img/backStageImg/noData-icon.png" alt="" />
      <p>暂无数据</p>
    </div>
    <pagination
      class="pagination jumpBtn"
      v-if="total > 0"
      :pageSize="formData.pageSize"
      :total="total"
      @pageChange="pageChange"
      @sizeChange="sizeChange"
    />
  </div>
</template>

<script>
import API from "@/apis/studentsEndAPI/personalCenterAPI";
import { EventBus } from "@/assets/js/eventBus";
import { getDay, getStatus } from "@/assets/js/format.js";
import Pagination from "@comps/backStageComponent/pagination";
export default {
  name: "beginShow",
  components: {
    Pagination,
  },
  data() {
    return {
      total: "",
      formData: {
        page: 1,
        pageSize: 6,
        courseType: 2,
        userId: "",
        keyword: "",
      },
      datas: [],
    };
  },
  mounted() {
    this.getLive();
  },
  methods: {
    getDay,
    async getLive() {
      if (sessionStorage.getItem("profile")) {
        this.formData.userId = JSON.parse(
          sessionStorage.getItem("profile")
        ).userId;
      } else {
        let data = await API.getProfile();
        if (data) {
          this.$store.commit("USER_INFO", data);
          sessionStorage.setItem("profile", JSON.stringify(data));
          this.formData.userId = data.userId;
        }
      }
      let { page, pageSize, courseType, userId, keyword } = this.formData;
      let data = await API.getLive(page, pageSize, courseType, userId, keyword);
      if (data && !data.status) {
        this.datas = data.dataList;
        this.total = data.rowCount;
      }
    },
    async collection(itemId) {
      let userId = this.formData.userId;
      let params = {
        itemType: "1",
        itemId,
        collectType: "1",
      };
      let data = await API.deleteCollect(userId, params);
      if (data && !data.status) {
        this.getLive();
      }
    },
    joinLive(val) {
      this.$router.push({
        path: "/dibbleDetail",
        query: { id: val.courseId },
      });
    },
    pageChange(val) {
      this.formData.page = val;
      this.getLive();
    },
    sizeChange(val) {
      this.formData.pageSize = val;
      this.getLive();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/common.scss";
@import "@/assets/css/studentsEndCSS/liveAndbegin";
.nodata {
  margin: auto;
  padding-top: 8%;
  img {
    width: 20%;
    height: 20%;
    padding-bottom: 30px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  p {
    text-align: center;
    padding-bottom: 30px;
    font-size: 16px;
  }
}
</style>
